<template>
  <!-- 运单列表 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>
    <div class="screen-content" v-if="showType == 'waybill'">
      <div class="search">
        <el-form label-position="left" label-width="80px" :model="searchForm" ref="searchForm">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="提单号" prop="extractIdentity">
                <el-input v-model="searchForm.extractIdentity" placeholder="搜索提单号" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="派单方" prop="merchantNickname">
                <el-input v-model="searchForm.merchantNickname" placeholder="搜索派单方名称" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话" prop="merchantPhone">
                <el-input v-model="searchForm.merchantPhone" placeholder="联系电话" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="订单地址" prop="firstPlaceCity">
                <el-input v-model="searchForm.firstPlaceCity" placeholder="运单地址" style="width: 250px;"
                  size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="作业地点" prop="firstPlaceDistrict">
                <el-input style="width:250px;" v-model="searchForm.firstPlaceDistrict" placeholder="请输入作业订单"
                  size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="配送形式" prop="waybillDeliveryMode">
                <el-select v-model="searchForm.waybillDeliveryMode" placeholder="请选择" clearable style="width: 250px;"
                  size="small">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="单背" value="Single"></el-option>
                  <el-option label="双背" value="Double"></el-option>
                  <el-option label="多背" value="More"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="开始时间">
                <el-date-picker v-model="searchForm.starDate" type="date" placeholder="选择接单开始时间" size="small"
                  value-format="yyyyMMdd" style="width: 100%;" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束时间">
                <el-date-picker v-model="searchForm.endDate" type="date" placeholder="选择接单结束时间" size="small"
                  value-format="yyyyMMdd" style="width: 100%;" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <div style="margin-top: 5px;margin-left: 120px;">
                <el-button type="primary" size="mini" @click="searchClick">筛选</el-button>
                <el-button type="primary" size="mini" @click="resetClick">重置</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <el-table :data="orderList" stripe border style="width: 100%; margin-bottom: 20px" row-key="id"
        v-loading="queryLoading" element-loading-text="正在努力加载中...">
        <el-table-column prop="extractIdentity" label="提单号" show-overflow-tooltip width="170" />
        <el-table-column label="操作" width="200">
          <template v-slot="scope">
            <el-button size="mini" type="warning" @click="infoDetail(scope.row)">详情</el-button>
            <el-button size="mini" type="danger" @click="orderTakHandel(scope.row)">运单列表</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="订单状态" show-overflow-tooltip>
          <template v-slot="scope">
            <div v-if="scope.row.state == 1" class="text-suggest">待指派集卡</div>
            <div v-if="scope.row.state == 2" class="text-style">车队已接单</div>
            <div v-if="scope.row.state == 3">已指派集卡</div>
          </template>
        </el-table-column>
        <el-table-column prop="businessLicenseName" label="派单方" show-overflow-tooltip />
        <el-table-column prop="deliveryMode" label="形式" show-overflow-tooltip>
          <template v-slot="scope">
            <div v-if="scope.row.deliveryMode == 'Double'" class="text-suggest">双背</div>
            <div v-if="scope.row.deliveryMode == 'Single'" class="text-style">单背</div>
            <div v-if="scope.row.deliveryMode == 'More'">多背</div>
          </template>
        </el-table-column>
        <el-table-column prop="orderTakNumber" label="总箱量" show-overflow-tooltip />
        <el-table-column prop="sendOrdersNumber" label="已派单数" show-overflow-tooltip />
        <el-table-column prop="sendBoxNumber" label="已派单箱量" show-overflow-tooltip />
        <el-table-column prop="completeNumber" label="已结案箱量" show-overflow-tooltip />
        <el-table-column prop="deliveryOnceCost" label="每车运费" show-overflow-tooltip width="110">
          <template v-slot="scope">
            <div>{{ (scope.row.deliveryOnceCost || 0) }} 元</div>
          </template>
        </el-table-column>
        <el-table-column prop="deliveryEstimateTotalCost" label="订单总运费" show-overflow-tooltip width="110">
          <template v-slot="scope">
            <div>{{ (scope.row.deliveryEstimateTotalCost || 0) }} 元</div>
          </template>
        </el-table-column>
        <el-table-column prop="settleAmount" label="已结算费用" show-overflow-tooltip width="110">
          <template v-slot="scope">
            <div>{{ (scope.row.settleAmount || 0) }} 元</div>
          </template>
        </el-table-column>

        <el-table-column prop="cradleLbs" label="提箱地" show-overflow-tooltip width="250">
          <template v-slot="scope">
            <div>{{ `${scope.row.firstPlaceProvince} ${scope.row.firstPlaceCity} ${scope.row.firstPlaceDistrict}
              ${scope.row.firstPlaceDetail}` }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="assembleLbs" label="送货/装货地" show-overflow-tooltip width="250">
          <template v-slot="scope">
            <div>{{ `${scope.row.secondPlaceProvince} ${scope.row.secondPlaceCity} ${scope.row.secondPlaceDistrict}
              ${scope.row.secondPlaceDetail}` }}</div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination background align="center" layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 50, 100]"
        :current-page="searchForm.page" :page-size="searchForm.limit" @current-change="pageChange"
        :total="totalCount" />
    </div>

    <div class="screen-content" v-if="showType == 'waybillOrder'">
      <el-page-header @back="back"
        :content="'《 ' + currentWaybillData.businessLicenseName + ' 》 运单列表'"></el-page-header>
      <el-row :gutter="20" style="margin-top: 10px;">
        <el-col :span="currentWaybillData.waybillIncludeThirdPlace == 'Yes' ? 8 : 12"
          style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
          <div class="top-info">
            <div class="info-title">提箱地</div>
            <div class="info-address">{{ currentWaybillData.firstPlaceDetail || '' }}</div>
            <div class="info-lbs">{{ currentWaybillData.firstPlaceProvince }} {{ currentWaybillData.firstPlaceCity }}
              {{ currentWaybillData.firstPlaceDistrict }}</div>
            <div class="info-tip">提箱有效时间段：</div>
            <div class="info-time">{{ dateFormat2Str(Math.trunc(currentWaybillData.firstPlaceBeginTime / 100)) }} - {{
              dateFormat2Str(Math.trunc(currentWaybillData.firstPlaceEndTime / 100)) }}</div>
          </div>
        </el-col>
        <el-col :span="currentWaybillData.waybillIncludeThirdPlace == 'Yes' ? 8 : 12"
          style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
          <div class="top-info">
            <div class="info-title orange">送货/装货地</div>
            <div class="info-address">{{ currentWaybillData.secondPlaceDetail || '' }}</div>
            <div class="info-lbs">{{ currentWaybillData.secondPlaceProvince }} {{ currentWaybillData.secondPlaceCity }}
              {{ currentWaybillData.secondPlaceDistrict }}</div>
            <div class="info-tip">送货/装货有效时间段：</div>
            <div class="info-time">{{ dateFormat2Str(Math.trunc(currentWaybillData.secondPlaceBeginTime / 100)) }} - {{
              dateFormat2Str(Math.trunc(currentWaybillData.secondPlaceEndTime / 100)) }}</div>
          </div>
        </el-col>
        <el-col :span="8" v-if="currentWaybillData.waybillIncludeThirdPlace == 'Yes'"
          style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
          <div class="top-info">
            <div class="info-title green">收箱地</div>
            <div class="info-address">{{ currentWaybillData.thirdPlaceDetail || '' }}</div>
            <div class="info-lbs">{{ currentWaybillData.thirdPlaceProvince }} {{ currentWaybillData.thirdPlaceCity }}
              {{ currentWaybillData.thirdPlaceDistrict }}</div>
            <div class="info-tip">收箱有效时间段：</div>
            <div class="info-time">{{ dateFormat2Str(Math.trunc(currentWaybillData.thirdPlaceBeginTime / 100)) }} - {{
              dateFormat2Str(Math.trunc(currentWaybillData.thirdPlaceEndTime / 100)) }}</div>
          </div>
        </el-col>
      </el-row>

      <!--表单-->
      <el-form :inline="true" size="mini" style="margin-top: 20px;">
        <!-- TODO: 页面 - 查询表单 -->
        <!-- 说明：数据与searchForm中数据对应 begin-->
        <el-form-item label="运单地址">
          <el-input v-model="searchBillForm.searchKey" placeholder="运单地址" style="width: 178px;"></el-input>
        </el-form-item>
        <el-form-item label="司机电话">
          <el-input v-model="searchBillForm.driverContactPhone" placeholder="司机联系电话" style="width: 178px;"></el-input>
        </el-form-item>
        <el-form-item label="配送形式">
          <el-select v-model="searchBillForm.waybillDeliveryMode" placeholder="请选择" clearable style="width: 178px;">
            <el-option label="全部" value=""></el-option>
            <el-option label="单背" value="Single"></el-option>
            <el-option label="双背" value="Double"></el-option>
            <el-option label="多背" value="More"></el-option>
          </el-select>
        </el-form-item>
        <br>

        <el-form-item label="运单状态">
          <el-select v-model="searchBillForm.orderState" placeholder="运单状态" clearable style="width: 178px;">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in orderState" :key="item.vlaue" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="searchBillForm.starDate" type="date" placeholder="选择接单开始时间" value-format="yyyyMMdd"
            style="width: 178px;" />
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="searchBillForm.endDate" type="date" placeholder="选择接单结束时间" value-format="yyyyMMdd"
            style="width: 178px;" />
        </el-form-item>
        <!-- 页面 - 查询表单 （数据与searchForm中数据对应）end -->

        <el-form-item>
          <el-button type="primary" @click="searchBill"><i class="fa fa-search"></i> 查询</el-button>
          <!-- <el-button type="primary" @click="exportData"><i class="fa el-icon-download"></i> 导出</el-button> -->
        </el-form-item>
      </el-form>

      <!-- 数据表 -->
      <el-table :data="tableBillData" stripe border style="width: 100%; margin-bottom: 20px" row-key="id"
        v-loading="queryLoading" element-loading-text="正在努力加载中...">
        <!-- TODO: 页面 - 数据表格 -->
        <!-- 说明：数据与查询列表返回数据对应 begin-->
        <el-table-column prop="id" label="运单编号" show-overflow-tooltip width="170" />
        <el-table-column label="操作" width="180">
          <template v-slot="scope">
            <el-button size="mini" type="warning" @click="rowTakDetail(scope.row)">详情</el-button>
            <el-button size="mini" type="warning" @click="adjustHandle(scope.row)">调整运费</el-button>
            <el-button size="mini" type="success" v-if="scope.row.orderState == 'Wait'"
              @click="designateCarHandel(scope.row)">指派集卡</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="orderState" label="运单状态" show-overflow-tooltip width="125">
          <template v-slot="scope">
            <div>{{ orderState[scope.row.orderState].label }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="outStock" label="是否无货回空" show-overflow-tooltip width="125">
          <template v-slot="scope">
            <div>{{ scope.row.outStock === 'Yes' ? "是" : scope.row.outStock === 'No'?"否":"" }} </div>
          </template>
        </el-table-column>
        <el-table-column prop="driverName" label="司机姓名" show-overflow-tooltip width="115" />
        <el-table-column prop="driverContactPhone" label="司机电话" show-overflow-tooltip width="105">
          <template v-slot="scope">
            <div>{{ scope.row.driverContactPhone || '' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="teamCarNumber" label="车牌号" show-overflow-tooltip width="90">
          <template v-slot="scope">
            <div>{{ scope.row.teamCarNumber || '' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="containerAmount" label="箱量" show-overflow-tooltip width="50">
          <template v-slot="scope">
            <div>{{ scope.row.containerAmount || 0 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="waybillDeliveryMode" label="形式" :sortable="'custom'" show-overflow-tooltip width="75">
          <template v-slot="scope">
            <div v-if="scope.row.waybillDeliveryMode == 'Double'" class="text-suggest">双背</div>
            <div v-if="scope.row.waybillDeliveryMode == 'Single'" class="text-style">单背</div>
            <div v-if="scope.row.waybillDeliveryMode == 'More'">多背</div>
          </template>
        </el-table-column>
        <el-table-column prop="freightDriverCost" label="运费" :sortable="'custom'" show-overflow-tooltip width="90">
          <template v-slot="scope">
            <div>{{ scope.row.orderState == 'Undo' ? 0 : (scope.row.freightDriverCost || 0) }}元</div>
          </template>
        </el-table-column>
        <el-table-column prop="otherMoneyCost" label="其他费用">
          <template v-slot="scope">
            <div>{{ scope.row.otherMoneyCost || 0 }}元
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="urgentCost" label="加急费">
          <template v-slot="scope">
            <div>{{ scope.row.urgentDriverCost || 0 }}元
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
        </el-table-column>

        <!-- 页面 - 数据表格 （数据与查询列表返回数据对应）end -->
      </el-table>

      <!-- 分页 -->
      <el-pagination background align="center" layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 50, 100]"
        :current-page="searchBillForm.page" :page-size="searchBillForm.limit" @current-change="pageBillChange"
        :total="totalBillCount" />

    </div>

    <!--指派集卡-->
    <el-dialog title="指派集卡" :visible.sync="designateDialogShow" width="80%">
      <el-form :model="designateFrom" :rules="designateRules" ref="designateFrom" label-position="top">
        <el-row :gutter="5">
          <el-col :span="8">
            <el-form-item label="车牌号" label-width="100px" prop="licencePlateId">
              <el-select v-model="designateFrom.licencePlateId" filterable placeholder="请选择" clearable
                style="width: 100%;" size="small" @change="selectLicencePlateId">
                <el-option v-for="(item, index) in carInfoList" :key="index"
                  :label="item.licencePlate + `-` + (item.driverUserName)" :value="item.vehicleId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="司机姓名" prop="driverName">
              <el-input v-model="designateFrom.driverName" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="司机电话" prop="driverPhone">
              <el-input v-model="designateFrom.driverPhone" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="5">
          <el-col :span="8">
            <el-form-item label="运费" prop="freightDriverCost">
              <el-input v-model="designateFrom.freightDriverCost" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="加急费" prop="urgentCost">
              <el-input v-model="designateFrom.urgentCost" size="small"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="是否无货回空" prop="outStock">
              <template>
                <el-radio size="small" v-model="designateFrom.outStock" label="Yes">是</el-radio>
                <el-radio size="small" v-model="designateFrom.outStock" label="No">否</el-radio>
              </template>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row :gutter="5">
          <el-col :span="8">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="designateFrom.remark" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他费用项" prop="otherMoneyCost">
              <el-input v-model="designateFrom.otherMoneyCost" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer">
        <el-button type="primary" @click="submitForm('designateFrom')">确认</el-button>
      </div>
    </el-dialog>

    <!--调整运费金额-->
    <el-dialog title="调整运费金额" :visible.sync="adjustDialogShow" width="50%">
      <el-form :model="aadjustForm" :rules="aadjustRules" ref="aadjustForm" label-position="top">
        <el-row :gutter="5">
          <el-col :span="8">
            <el-form-item label="运费" prop="freightDriverCost">
              <el-input v-model="aadjustForm.freightDriverCost" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="加急费" prop="urgentCost">
              <el-input v-model="aadjustForm.urgentCost" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="8">
            <el-form-item label="补充费用" prop="replenish">
              <el-input v-model="aadjustForm.replenish" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="aadjustForm.remark" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否无货回空" prop="outStock">
              <template>
                <el-radio size="small" v-model="aadjustForm.outStock" label="Yes">是</el-radio>
                <el-radio size="small" v-model="aadjustForm.outStock" label="No">否</el-radio>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitAaJustForm('aadjustForm')">确认</el-button>
      </div>
    </el-dialog>

    <!--详情-->
    <OrderDetail ref="orderDetailRef"></OrderDetail>
    <!--派单详情-->
    <OrderTakDetail ref="orderTakDetailRef"></OrderTakDetail>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Header from '../../components/Header.vue'

import OrderTakDetail from './components/OrderTakDetail.vue';
import OrderDetail from './components/OrderDetail.vue';

export default {
  name: 'order_taK_list',
  components: { Header, OrderTakDetail, OrderDetail },
  data() {
    return {
      dayjs,

      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        merchantNickname: null,
        merchantPhone: null,
        extractIdentity: null,
        waybillDeliveryMode: null,
        //作业地点
        firstPlaceCity: null,
        firstPlaceDistrict: null,

        starDate: null,
        endDate: null,
      },
      orderList: [],//订单列表
      totalCount: 0,
      queryLoading: false,

      //指派集卡
      designateDialogShow: false,
      designateData: {},
      designateFrom: {
        //车牌
        licencePlateId: null,
        //司机
        driverName: null,
        driverPhone: null,
        //运费
        freightDriverCost: 0,
        //加急费
        urgentCost: 0,
        //是否无货回空
        outStock: "Yes",
        remark: null,
        //其他费用
        otherMoneyCost: null,
      },
      designateRules: {
        licencePlateId: { required: true, message: '请选择', trigger: 'blur' },
        driverName: { required: true, message: '请输入', trigger: 'blur' },
        driverPhone: { required: true, message: '请输入', trigger: 'blur' },
        freightDriverCost: { required: true, message: '请输入', trigger: 'blur' },
      },
      carInfoList: [],


      showType: "waybill",
      currentWaybillData: {},

      totalBillCount: 0,
      tableBillData: [],


      searchBillForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        teamOrderId: "",
        waybillMerchantNickname: null,
        driverContactPhone: null,
        waybillDeliveryMode: null,
        starDate: null,
        endDate: null,
        orderState: null
      },
      orderState: {
        Wait: { label: '待接单', value: 'Wait' },
        Accept: { label: '已接单', value: 'Accept' },
        First_Place_Confirm: { label: '提箱确认', value: 'First_Place_Confirm' },
        Second_Place_Arrive: { label: '抵达卸货地', value: 'Second_Place_Arrive' },
        Second_Place_Confirm: { label: '卸货/送货确认', value: 'Second_Place_Confirm' },
        Third_Place_Confirm: { label: '收箱确认', value: 'Third_Place_Confirm' },
        Merchant_Confirm: { label: '已结案', value: 'Merchant_Confirm' },
        Undo: { label: '已撤单', value: 'Undo' },
        Refuse: { label: '转单拒绝', value: 'Refuse' },
        back_address: { label: '返箱回提箱地确认', value: 'back_address' },
        driver_finish: { label: '返箱至提箱地完成', value: 'driver_finish' },
      },

      //调整运费金额
      adjustDialogShow: false,
      adjustData: {},
      aadjustForm: {
        //运费
        freightDriverCost: 0,
        //加急费
        urgentCost: 0,
        remark: null,
        //补充费用
        replenish: 0,
        //无货回空
        outStock: null
      },
      aadjustRules: {
        freightDriverCost: { required: true, message: '请输入', trigger: 'blur' }
      },
    }
  },
  mounted() {
    this.searchForm.page = 1;
    this.orderList = [];
    this.totalCount = 0;
    this.getOrderList()
  },
  methods: {
    // 根据用户id获取派单方信息
    async getOrderList() {
      this.queryLoading = true;
      let res = await this.$req({
        url: `/waybillPc/queryTeamOrder`,
        method: 'GET',
        data: this.searchForm
      })

      if (res.code == 200) {
        this.orderList = res.data;
        this.totalCount = res.count;
      }
      this.queryLoading = false;
    },
    pageChange(e) {
      this.searchForm.page = e;
      this.getOrderList();
    },
    trainNumber(data) {
      if (data.deliveryMode == 'Double') {
        return parseInt(data.containerCirculateAmount / 2);
      } else if (data.deliveryMode == 'More') {
        return parseInt(data.containerCirculateAmount / 3);
      } else {
        return data.containerCirculateAmount;
      }
    },
    async infoDetail(data) {
      this.$refs.orderDetailRef.showInfo(data);
    },
    resetClick() {
      this.searchForm = {
        page: 1,
        limit: 10,
        searchKey: null,
        merchantNickname: null,
        driverContactPhone: null,
        waybillDeliveryMode: null,
        starDate: null,
        endDate: null,
        orderState: null,
        waybillExtractIdentity: null,
        firstPlaceDetail: null,
        workSite: null
      }
      this.getOrderList();

    },
    searchClick() {
      this.searchForm.page = 1;
      this.orderList = [];
      this.totalCount = 0;

      this.getOrderList();
    },
    orderTakHandel(data) {
      this.currentWaybillData = data;
      this.showType = 'waybillOrder';

      this.searchBillForm.page = 1;
      this.searchBillForm.teamOrderId = this.currentWaybillData.teamOrderId;
      this.getBillList();
    },
    back() {
      this.currentWaybillData = {};
      this.showType = 'waybill'
    },
    async designateCarHandel(data) {
      this.designateData = data;
      this.designateDialogShow = true;
      this.designateFrom = {
        //车牌
        licencePlateId: null,
        //司机
        driverName: null,
        driverPhone: null,
        //运费
        freightDriverCost: data.freightDriverCost,
        //加急费
        urgentCost: data.urgentDriverCost,
        //是否无货回空
        outStock: null,
        remark: null,
        //其他费用
        otherMoneyCost: null,
      };

      let res = await this.$req({
        url: `/carTeamVehicle/getCarTeamVehicle`,
        method: 'GET',
      })
      if (res.code == 200) {
        this.carInfoList = res.data;
      }
    },
    selectLicencePlateId(e) {
      //在carInfoList 找到选择的对应的对象
      let output = this.carInfoList.find(object => object.vehicleId == e)
      this.designateFrom.licencePlateId = output.vehicleId;
      this.designateFrom.driverName = output.driverUserName;
      this.designateFrom.driverPhone = output.driverPhone;
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = {
            ...this.designateFrom,
            waybillOrderId: this.designateData.id,
          }
          console.log("tijiao ")
          let res = await this.$req({
            url: `/waybillPc/designateOrder`,
            method: 'POST',
            data: data
          })
          if (res.code == 200) {
            this.designateData = [];
            this.designateDialogShow = false;
            this.getBillList();

            this.$message({ type: 'success', message: '指派成功', duration: 1000 })
          } else {
            this.$message({ type: 'error', message: res.msg, duration: 1000 })
          }
        }
      })
    },
    searchBill() {
      this.searchBillForm.page = 1;
      this.getBillList();
    },
    async getBillList() {
      this.queryLoading = true

      let res = await this.$req({
        url: `/waybillOrder/queryForAdmin`,
        method: 'GET',
        data: this.searchBillForm
      })

      console.log(res.code,"res.coderes.code")
      if (res.code == 200) {
        this.tableBillData = res.data;
        this.totalBillCount = res.count;
      }
      this.queryLoading = false;
    },
    pageBillChange(e) {
      console.log(e,"eee")
      this.searchBillForm.page = e;
      this.getBillList();
    },
    adjustHandle(data) {
      this.aadjustForm = {
        //运费
        freightDriverCost: data.freightDriverCost,
        //加急费
        urgentCost: data.urgentDriverCost,
        remark: data.remark,
        //补充费用
        replenish: data.otherMoneyCost,
        outStock: data.outStock
      };
      this.adjustData = data;
      this.adjustDialogShow = true;

    },
    submitAaJustForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = {
            ...this.aadjustForm,
            waybillOrderId: this.adjustData.id,
          }
          console.log("tijiao ")
          let res = await this.$req({
            url: `/waybillPc/aaJustOrder`,
            method: 'POST',
            data: data
          })
          if (res.code == 200) {
            this.designateData = [];
            this.adjustDialogShow = false;
            this.getBillList();

            this.$message({ type: 'success', message: '调整成功', duration: 1000 })
          } else {
            this.$message({ type: 'error', message: res.msg, duration: 1000 })
          }
        }
      })
    },
    rowTakDetail(item) {
      this.$refs.orderTakDetailRef.showInfo(item);
    }
  }
}
</script>

<style scoped lang="stylus">
.container
  padding-top: 104px
  width 100vw
  .screen-content
    width 100vw
    .search
      width 1200px
      display: flex
      justify-content: center;
      .btns
        display: flex

    .top-info
      font-family: SourceHanSansSC-bold;
      .info-title 
        color: rgba(59, 143, 225, 100);
        font-size: 16px;
        margin-bottom: 10px;
      
      .orange 
        color: rgba(225, 142, 59, 100);
      
      .green 
        color: rgba(31, 172, 26, 100);
      
      .info-address 
        color: rgba(0, 0, 0, 1);
        font-size: 16px;
        margin-bottom: 4px;
      .info-lbs 
        color: rgba(67, 67, 67, 100);
        font-size: 14px;
        margin-bottom: 10px;
      
      .info-tip 
        color: rgba(133, 136, 142, 100);
        font-size: 12px;
        margin-bottom: 4px;
      
      .info-time 
        color: rgba(102, 102, 102, 100);
        font-size: 12px;
      
      .red 
        color: #da2222;
    
    
</style>
