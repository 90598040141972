<template>
<!-- 运单列表 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <!-- 筛选 -->
    <div class="screen" v-if="merchant_info">
      <div class="screen-content">
        <div class="search">
          <div class="search-item">
            <div class="label">提（运）单号：</div>
            <el-input size="small" v-model="params.extractIdentity" placeholder="请输入"></el-input>
          </div>
          
          <div class="search-item">
            <div class="label">订单状态：</div>
            <el-select size="small" v-model="params.waybillState" placeholder="请选择">
              <el-option
                v-for="item in state_obj"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <div class="label">发布时间：</div>
            <el-date-picker
             size="small"
              v-model="create_time"
              format="yyyy-MM-dd"
              value-format="yyyyMMdd"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
          <div class="search-item">
            <div class="label">订单地址：</div>
            <el-input size="small" v-model="params.searchKey" placeholder="请输入"></el-input>
          </div>

          <div class="btns">
            <div class="blue-btn" @click="searchClick">筛选</div>
            <div class="reset" @click="resetClick">重置</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" v-if="merchant_info">
      <!-- 列表 -->
      <div class="list">
        <div class="item" v-for="(item,index) in order_list" :key="item.id">
          <div class="item-top">
            <div class="item-top-opt">
              <div class="label">提（运）单号：</div>
              <div>{{item.extractIdentity}}</div>
            </div>
            <div class="item-top-opt" v-if="item.shipCompanyCode">
              <div class="label">船公司：</div>
              <div>{{item.shipCompanyCode}} {{item.shipCompanyName}}</div>
            </div>
            <div class="item-top-opt" v-if="item.importAndExport">
              <div class="label">类型：</div>
              <div>{{item.importAndExport=='Include'?'进出口运输':item.importAndExport=='None'?'无进出口运输':''}}</div>
              <div v-if="item.importAndExport&&item.goodsLoadingAndUnloading"> ，</div>
              <div>{{item.goodsLoadingAndUnloading=='None'?'无卸装货要求':item.goodsLoadingAndUnloading=='Include'?'有卸装货要求':''}}</div>
            </div>
            <div class="item-top-opt" v-if="item.carDeliveryDemand">
              <div class="label">配送要求：</div>
              <div>{{item.carDeliveryDemand}}</div>
            </div>
            <div class="item-top-opt">
              <div class="label">运费总额：</div>
              <div>{{item.deliveryEstimateTotalCost}} 元</div>
            </div>
            <div class="item-top-opt">
              <div class="label">当前需垫付费用：</div>
              <div>{{item.orderPlaceFee}} 元</div>
            </div>
          </div>
          <!-- 进度 -->
          <div class="progress">
            <div class="progress-state">
              <div class="black">起始</div>
            </div>
            
            <div class="progress-state">
              <div class="black">卸装</div>
              <img class="dot" src="@/assets/dot.png" alt="">
            </div>

            <div class="progress-state" v-if="item.includeThirdPlace=='Yes'">
              <div class="black" style="width:50px">目的地</div>
              <img class="dot" src="@/assets/dot.png" alt="">
            </div>
          </div>
          <!-- 信息 -->
          <div class="item-content">
            <!-- 信息 -->
            <div class="left">
              <div class="info-item">
                <div class="name">{{item.firstPlaceDetail}}</div>
                <div class="address">{{item.firstPlaceProvince}} {{item.firstPlaceCity}} {{item.firstPlaceDistrict}}</div>
                <div class="date">
                  <div class="label">提箱有效时间段：</div>
                  <div>{{item.firstPlaceBeginTime | timereplace}} — {{item.firstPlaceEndTime | timereplace}}</div>
                </div>
              </div>
              <div class="info-item">
                <div class="name">{{item.secondPlaceDetail}}</div>
                <div class="address">{{item.secondPlaceProvince}} {{item.secondPlaceCity}} {{item.secondPlaceDistrict}}</div>
                <div class="date">
                  <div class="label">送货/装货有效时间段：</div>
                  <div>{{item.secondPlaceBeginTime | timereplace}} — {{item.secondPlaceEndTime | timereplace}}</div>
                </div>
              </div>
              <div class="info-item" v-if="item.includeThirdPlace=='Yes'">
                <div class="name">{{item.thirdPlaceDetail}}</div>
                <div class="address">{{item.thirdPlaceProvince}} {{item.thirdPlaceCity}} {{item.thirdPlaceDistrict}}</div>
                <div class="date">
                  <div class="label">到目的地有效时间段：</div>
                  <div>{{item.thirdPlaceBeginTime | timereplace}} — {{item.thirdPlaceEndTime | timereplace}}</div>
                </div>
              </div>
            </div>
            <!-- 操作、 状态 -->
            <div class="operation">
              <div class="state" v-if="item.againState">转向二次派单</div>
              <div class="state" v-else-if="!item.onlineState">已下架</div>
              <div class="state" v-else-if="item.waybillState==1">待接单中</div>
              <div class="state" v-else-if="item.waybillState==2">接单中</div>
              <div class="state" v-else-if="item.waybillState==3">全部接单结束</div>
              <div class="state" v-else-if="item.waybillState==4">运单均完成</div>
              <div class="state" v-else-if="item.waybillState==5">已结案</div>
            </div>
          </div>
          <div class="item-bottom">
            <div class="left">
              <div class="info-item-bottom">
                <div>
                  <img src="@/assets/box.png" alt="">
                  <div class="label">箱子总量：</div>
                  <div>{{item.containerTotalAmount}}个</div>
                </div>
                <div>
                  <img src="@/assets/box.png" alt="">
                  <div class="label">剩余箱量：</div>
                  <div style="color:#FF9100">{{item.containerCirculateAmount}}个</div>
                </div>
              </div>
              <div class="info-item-bottom">
                <div style="margin-left:100px">
                  <img src="@/assets/money.png" alt="">
                  <div class="label">预计运费总额：</div>
                  <div>{{item.deliveryEstimateTotalCost}}元<span v-if="item.urgentState=='Yes'">(加急费：{{ item.urgentDeliveryOnceCost }}元)</span></div>
                </div>
              </div>
              <div class="info-item-bottom">
                <div>
                  <img src="@/assets/money.png" alt="">
                  <div class="label">当前运费总额：</div>
                  <div>{{item.deliveryEstimateTotalCost - item.deliveryCirculateCost}}元</div>
                </div>
              </div>
              <div class="info-item-bottom">
                <div>
                  <img src="@/assets/box.png" alt="">
                  <div class="label">箱门朝向：</div>
                  <div>{{item.orientationStr}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="item-bottom">
            <div class="btns">
              <!-- <div class="red-btn" v-if="(item.containerTotalAmount - item.containerCirculateAmount == 0) && item.acceptEndTime>now_time && !item.effectiveOrderAmount" @click="delClick(item.id)">删除订单</div> -->
              <div class="white-btn" @click="detailClick(item.id,index)">订单详情</div>
              <div class="white-btn" v-if="item.acceptEndTime>now_time && (item.containerTotalAmount == item.containerCirculateAmount) && item.onlineState" @click="editClick(item.id,index)">修改订单</div>

              <div class="white-btn" v-if="item.containerCirculateAmount > 0 && (item.acceptEndTime<=now_time || !item.onlineState) && !item.againState" @click="$router.push({path:'/send_bill',query:{id:item.id,type:'again'}})">二次派单</div>
              <div class="white-btn" v-if="item.onlineState" @click="editNumClick(item,index)">修改剩余箱量</div>
              <div class="blue-btn" @click="$router.push({path:'/waybill_list',query:{id:item.id}})">查看运单</div>
              <!-- <div class="blue-btn" v-if="item.acceptEndTime<=now_time || (item.acceptEndTime>now_time && item.effectiveOrderAmount)" @click="$router.push({path:'/waybill_list',query:{id:item.id}})">查看运单</div> -->
              <div class="red-btn" v-if="item.acceptEndTime>now_time && (item.containerTotalAmount == item.containerCirculateAmount) && item.onlineState" @click="unOnlineClick(item.id,index)">下架</div>
              
            </div>
          </div>
          <div class="waybill-list">
            <div class="look" @click="openClick(index)">
              <div>查看运单关键信息</div>
              <i class="el-icon-arrow-down" v-if="item.waybill_show" ></i>
              <i class="el-icon-arrow-up" v-else></i>
            </div>
            <el-table :data="item.waybill_list||[]" style="width: 100%" v-if="item.waybill_show">
              <el-table-column type="index" label="序号" width="50" />
              <el-table-column prop="driverName" label="接单司机" min-width="180" />
              <el-table-column prop="driverCarNumber" label="车牌号" min-width="180" />
              <el-table-column prop="orderState" label="状态" min-width="180" >
                <template  slot-scope="scope">
                  {{getOrderState(scope.row)}}
                </template>
              </el-table-column>
              <el-table-column prop="containerSealNums" label="箱号" min-width="180" show-overflow-tooltip>
                <template  slot-scope="scope">
                  <div v-for="(opt,optIndex) in JSON.parse(scope.row.containerSealNums)" :key="optIndex">{{opt.identity}} <span v-if="optIndex>0">,</span></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="操作" width="220">
                <template  slot-scope="scope">
                  <el-button size="mini" @click="waybillDetail(scope.row)">运单详情</el-button>
                  <el-button v-if="scope.row.orderState=='Third_Place_Confirm'|| 
                  scope.row.orderState=='driver_finish' || 
                  (scope.row.orderState=='Second_Place_Confirm' &&scope.row.waybillIncludeThirdPlace=='No')"
                  size="mini" type="primary" @click="waybillConfirm(scope.row)">运单确认完成</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-if="order_list.length == 0">
          <div class="no-data">没有更多数据了</div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
        v-if="count"
        background
        :page-size="params.limit"
        layout="prev, pager, next"
        @current-change="pageChange"
        :total="count">
      </el-pagination>
    </div>

  <!-- 修改剩余箱量弹窗 -->
    <div class="mask" v-if="editnum_show">
      <div class="pop">
        <div class="title">
          <div>修改剩余箱量</div>
          <img class="close" src="@/assets/close.png" alt="" @click="editnum_show=false">
        </div>
        <div class="input">
          <div>剩余箱量</div>
          <el-input-number size="small" v-model="containerCirculateAmount" :min="0" label="剩余箱量"></el-input-number>
        </div>
        <div class="btns">
          <div class="white-btn" @click="editnum_show=false">取消</div>
          <div class="blue-btn" @click="editnumSubmit">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
export default {
  name: 'order_list',
  components:{Header},
  data(){
    return{
      params:{ //订单列表参数
        limit:10,
        page:1,
        searchKey:'',
        extractIdentity:'',
        starDate:'',
        endDate:'',
        dateType:'Create_Seconds',
        sortField:'create_time',
        merchantContactPhone:'',
        waybillState:0
      },
      order_list:[],//订单列表
      now_time:'',//当前时间
      create_time:[],
      editnum_show:false,//修改剩余箱量弹窗
      editnum_data:{},
      containerCirculateAmount:0,//剩余箱子数量
      count:0,
      state_obj:[
        {name:'全部',code:0},
        {name:'待接单中',code:1},
        {name:'接单中',code:2},
        {name:'全部接单结束',code:3},
        {name:'运单均完成',code:4},
        {name:'已结案',code:5},
      ],
      waybillState:0,
      orderState:{
        Wait:{label:'待接单',value:'Wait'},
        Accept:{label:'已接单',value:'Accept'},
        First_Place_Confirm:{label:'提箱确认',value:'First_Place_Confirm'},
        Second_Place_Arrive:{label:'到达卸货/送货地',value:'Second_Place_Arrive'},
        Second_Place_Confirm:{label:'卸/装货已确认',value:'Second_Place_Confirm'},
        Third_Place_Confirm:{label:'收箱确认',value:'Third_Place_Confirm'},
        Merchant_Confirm:{label:'已结案',value:'Merchant_Confirm'},
        Undo:{label:'已撤单',value:'Undo'},
        Refuse:{label:'转单拒绝',value:'Refuse'},
        back_address:{label:'返箱回提箱地确认',value:'back_address'},
        driver_finish:{label:'返箱至提箱地完成',value:'driver_finish'},
      },
      merchant_info:null,
    }
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      // vm.params.userId = JSON.parse(localStorage.getItem('userinfo')).id
      vm.getMerchant()
      vm.getNowTime()
      vm.timer = setInterval(() => {
        vm.getNowTime()
      }, 1000*60);
      vm.getInfo();
    })
  },
  beforeRouteLeave(to, from, next){
    if(this.timer){
      clearInterval(this.timer)
    }
    next()
  },
  watch:{
    editnum_show(to){
      if(!to){
        this.editnum_data = {}
      }
    }
  },
  methods:{
    // 根据用户id获取派单方信息
    async getMerchant(){
      let res = await this.$req({
        url:`/merchant/detailByUser/${JSON.parse(localStorage.getItem('userinfo')).id}`,
        method:'GET'
      })
      if(res.code==200){
        this.merchant_info = res.data || ''
        this.merchant_info.quotaUnused = this.merchant_info.quotaUnused/100;
        localStorage.setItem('merchant',JSON.stringify(res.data))
        console.log('this.merchant_info',this.merchant_info.authState)
        if(this.merchant_info) {
          this.getOrders()
        } else {

        }
      }
    },
    getOrderState(data) {
      var label = this.orderState[data.orderState].label;
      return label
    },
    async getInfo(){
      let res = await this.$req({
        url:'/merchant/detailBySelf'
      })
      if(res.code==200){
        this.params.merchantContactPhone = res.data.contactPhone
      }
    },
    async waybillConfirm(data){
      var param_data = {
        freightDriverManualCost:Number(data.freightDriverCost),
        freightServiceFeeManualCost:Number(data.freightServiceFeeCost),
        placeManuaFee:Number(Number((data.firstPlaceFee||0)*1)+Number((data.secondPlaceFee||0)*1)+Number((data.thirdPlaceFee||0)*1)),
        urgentDriverManualCost:Number(data.urgentDriverCost),
        urgentServiceFeeManualCost:Number(data.urgentServiceFeeCost),
      };
      // console.log(param_data)
      // param_data = JSON.stringify(param_data);
      // console.log(param_data)
      // console.log(data.id)
      let res = await this.$req({
        url:`/waybillOrder/${data.id}/merchant/confim`,
        method:'POST',
        data:param_data
      })
      if(res.code==200){
        this.getOrders()
        this.$message({
          type:'success',
          message:'操作成功'
          , duration:1000
        })
      }else{
        this.$message({ message: res.msg, duration:1000});
      }
    },
    // 点击跳转运单详情
    waybillDetail({id,waybillId}){
      this.$router.push({path:'/waybill_detail',query:{id,waybillId}})
    },
    async openClick(index){
      let detailRes = await this.$req({
        url:`/waybill/` + this.order_list[index].id,
        method:'GET'
      })
      if(detailRes.code == 200) {
        this.$set(this.order_list[index],'containerCirculateAmount',detailRes.data.containerCirculateAmount);
        this.$set(this.order_list[index],'waybillState',detailRes.data.waybillState);
      }
      this.$set(this.order_list[index],'waybill_show',!this.order_list[index].waybill_show)
      if(this.order_list[index].waybill_show) {
        let data = this.order_list[index]
        let res = await this.$req({
          url:`/waybillOrder/queryForMerchant`,
          method:'GET',
          data:{
            page:1,
            limit:-1,
            waybillId:data.id,
            sortField:'create_time',
          }
        })
        if(res.code==200){
          this.$set(this.order_list[index],'waybill_list',res.data)
        }
      }
    },
    isDouble(allCarFreightCirculateCost){
      this.$confirm('此订单为双背，修改箱量为奇数，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async () => {
          let res = await this.$req({
            url:`/waybill/${this.editnum_data.id}/amountAndCost`,
            method:'POST',
            data:{
              containerCirculateAmount:this.containerCirculateAmount,
              deliveryCirculateCost:allCarFreightCirculateCost,
            }
          })
          if(res.code==200){
            this.$message({
              type:'success',
              message:'修改成功'
              , duration:1000
            })
            this.getOrders()
            this.editnum_show = false
          }else{
            this.$message({
              message:res.msg
              , duration:1000
            })
          }
        })
    },
    // 修改剩余箱量点击提交按钮
    async editnumSubmit(){
      // 运费
      let allCarFreightCirculateCost = 0
      if(this.editnum_data.deliveryMode=='Double'){ 
        // 双背
        if(this.containerCirculateAmount%2 == 1){
          allCarFreightCirculateCost = (this.containerCirculateAmount+1)/2*this.editnum_data.deliveryOnceCost
        }else{
          allCarFreightCirculateCost = this.containerCirculateAmount/2*this.editnum_data.deliveryOnceCost
        }
      }else if(this.editnum_data.deliveryMode=='Single'){ 
        // 单背
        allCarFreightCirculateCost = this.editnum_data.deliveryOnceCost*this.containerCirculateAmount
      }else if(this.editnum_data.deliveryMode=='More'){ 
        // 多背
        if(this.editnum_data.deliveryOnceAmount){
          if(this.containerCirculateAmount%this.editnum_data.deliveryOnceAmount){
            allCarFreightCirculateCost = (parseInt(this.containerCirculateAmount/this.editnum_data.deliveryOnceAmount)+1)*this.editnum_data.deliveryOnceCost
          }else{
            allCarFreightCirculateCost = this.containerCirculateAmount/this.editnum_data.deliveryOnceAmount*this.editnum_data.deliveryOnceCost
          }
        }else{
          allCarFreightCirculateCost = 0
        }
      }
      // console.log('containerCirculateAmount',this.containerCirculateAmount)
      // console.log('allCarFreightCirculateCost',allCarFreightCirculateCost)
      console.log('箱子总量：',this.editnum_data.containerTotalAmount)
      console.log('原剩余箱量',this.editnum_data.containerCirculateAmount)
      console.log('现剩余箱量',this.containerCirculateAmount)
      console.log('editnum_data',this.editnum_data)
      let res = await this.$req({
        url:`/waybill/${this.editnum_data.id}/amountAndCost`,
        method:'POST',
        data:{
          containerCirculateAmount:this.containerCirculateAmount,
          deliveryCirculateCost:allCarFreightCirculateCost,
          merchantId:JSON.parse(localStorage.getItem('merchant')).id
        }
      })
      if(res.code==200){
        this.$message({
          type:'success',
          message:'修改成功'
          , duration:1000
        })
        await this.getOrders()
        this.editnum_show = false
      }else{
        this.$message({
          message:res.msg
          , duration:1000
        })
      }
    },
    // 点击修改剩余箱量
    editNumClick(data,index){
      this.editnum_show = true
      this.editnum_data = data
      this.containerCirculateAmount = data.containerCirculateAmount
    },
    // 点击订单详情
    detailClick(id){
      this.$router.push({path:'/order_detail',query:{id}})
    },
    // 点击下架
    unOnlineClick(id) {
      this.$confirm('确定要下架吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
      .then(async () => {
        let res = await this.$req({
          url:`/waybill/${id}/unOnline`,
          method:'POST'
        })
        if(res.code==200){
          this.getOrders()
          this.$message({
            type: 'success',
            message: '下架成功!'
          });
        }else{
          this.$message({ message: res.msg, duration:1000});
        }
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    // 点击修改订单
    editClick(id){
      this.$router.push({path:'/send_bill',query:{id}})
    },
    // 点击删除订单
    delClick(id){
      this.$confirm('确定要删除订单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
      .then(async () => {
        let res = await this.$req({
          url:`/waybill/${id}`,
          method:'DELETE'
        })
        if(res.code==200){
          this.getOrders()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }else{
          this.$message({ message: res.msg, duration:1000});
        }
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    getNowTime () {
      let now = new Date();
      let year = now.getFullYear(); //获取完整的年份(4位,1970-????)
      let month = now.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let today = now.getDate(); //获取当前日(1-31)
      let hour = now.getHours(); //获取当前小时数(0-23)
      let minute = now.getMinutes(); //获取当前分钟数(0-59)
      let second = now.getSeconds(); //获取当前秒数(0-59)
      this.now_time = '' + year + this.fillZero(month) + this.fillZero(today) + this.fillZero(hour) + this.fillZero(minute) + this.fillZero(second)
      console.log('当前时间',this.now_time)
    },
    fillZero (str) {
      var realNum;
      if (str < 10) {
        realNum = '0' + str;
      } else {
        realNum = str;
      }
      return realNum;
    },
    // 点击筛选
    async searchClick(){
      this.params.page = 1
      if(this.create_time.length){
        this.params.starDate = this.create_time[0]
        this.params.endDate = this.create_time[1]
      }
      await this.getOrders()
    },
    // 点击重置
    resetClick(){
      this.params = {
        limit:10,
        page:1,
        searchKey:'',
        extractIdentity:'',
        starDate:'',
        endDate:'',
        dateType:'Create_Seconds',
        sortField:'create_time',
        waybillState:0
      }
      this.create_time = []
      this.waybillState = 0
      this.getOrders()
    },
    // 获取订单列表
    async getOrders(){
      console.log('waybillState',this.waybillState);
      if(this.params.waybillState > 0) {
        this.params.onlineState = true;
      } else {
        delete this.params.onlineState;
      }
      let res = await this.$req({
        url:'/waybill/queryForMerchant',
        method:"GET",
        data:this.params
      })
      if(res.code==200){
        this.order_list = res.data
        this.count = res.count
        
      }
    },
    pageChange(e){
      console.log(e)
      this.params.page = e
      this.getOrders()
    }
  }
}
</script>

<style scoped lang="stylus">
.container
  padding-top: 204px
  .screen
    width 100vw
    position: fixed
    background: #fff
    background-color: #fff
    top 70px
    left: 0
    z-index 1
    display: flex
    justify-content: center
    .screen-content
      width 1200px
      // 搜索
      .search
        padding: 10px 0 20px
        display: flex
        flex-wrap: wrap
        .search-item
          display: flex
          align-items: cennter
          margin-right: 25px
          margin-top: 20px
          .label
            width 112px
            text-align: right
          .el-input
            width 200px
            /deep/ .el-range-separator
              width 20px
            /deep/ .el-input__icon
              display: none
          .el-date-editor
            width 315px
            /deep/ .el-range-separator
              width 20px
            /deep/ .el-range-input
              width 50%
            /deep/ .el-input__icon
              display: none
          div
            line-height: 32px
        .btns
          display: flex
          margin-top: 20px
          margin-left: 32px
          div
            width: 84px;
            height: 32px;
            border-radius: 4px;
            font-size: 14px
            text-align: center
            line-height: 32px
            cursor pointer
          .blue-btn
            color: #fff
            background: #015DE7;
            margin-right: 10px
          .reset
            border: 1px solid #D0D3D6;
            color: #22272E
  &>.content
    width 1200px
    margin: 20px auto 0
    padding: 0
    background: transparent
    display: block
    // 列表
    .list
      width 100%
      min-height: calc(100vh - 250px)
      .no-data
        color:#999
        text-align:center
      &>.item
        padding: 10px 20px 0px
        margin-bottom: 10px
        background: #fff
        .item-top
          color #646a73
          display: flex
          flex-wrap: wrap
          justify-content: space-between
          margin-bottom: 10px
          border-bottom 1px solid #efefef
          .item-top-opt
            margin-bottom: 8px
            width 33.33%
            display: flex
            .label
              margin-right:10px
        // 进度
        .progress
          display: flex
          align-items: center
          margin-bottom: 10px
          .progress-state
            width 350px
            position relative
            .black
              width: 32px;
              height: 20px;
              border-radius: 2px;
              background-color: rgba(38, 44, 52, 1);
              color: #fff
              line-height: 20px
              text-align: center
              font-size: 12px
            .green
              display: flex
              align-items: center
              font-size: 14px
              color #00A045
              img 
                width 16px
                height 16px
                margin-right: 6px
            .orange
              display: flex
              align-items: center
              font-size: 14px
              color #FF9100
              img 
                width 16px
                height 16px
                margin-right: 6px
            .dot
              position: absolute
              height 4px
              margin-right: 44px
              width 214px
              top 50%
              right: 350px
              transform: translateY(-50%)
        .item-content
          display: flex
          justify-content: space-between
          &>.left
            display: flex
            .info-item
              width 350px
              color #646A73
              .name
                color: #22272E
                font-size: 16px
                line-height: 24px
                margin-bottom: 2px
              .address
                font-size: 14px
                line-height: 22px
                margin-bottom: 12px
              .date
                font-size: 12px
                line-height: 20px
                margin-bottom: 24px
                .label
                  color: #8F959E
            .info-item:nth-child(3){
              width: auto
            }
          .operation
            display: flex
            flex-direction: column  
            align-items: flex-end
            flex-shrink: 0
            .state
              color: #015DE7
              font-size: 20px
              line-height: 28px
            .red-state
              color: #F54A45
            .tag
              background: #FF9100;
              border-radius: 2px;
              font-size: 12px
              color: #fff
              padding: 1px 6px
              margin-top: 6px
        .item-bottom
          display: flex
          justify-content: space-between
          border-bottom 1px solid #efefef
          padding-bottom 10px
          .left
            display: flex
            .info-item-bottom
              width 350px
              display: flex
              align-items: center
              justify-content: space-between
              font-size: 12px
              line-height: 22px
              &>div 
                display: flex
                align-items: center
                img
                  width 22px
                  height 22px
                  margin-right: 10px
                .label
                  color: #8F959E   
            .fee
              width: auto  
          .btns
            display: flex
            align-items: flex-end
            padding-right: 16px 
            padding-top: 8px
            div
              padding: 5px 10px
              border-radius: 4px;
              text-align: center
              font-size: 14px
              margin-left: 10px
              cursor pointer
            .white-btn
              border: 1px solid rgba(208, 211, 214, 1);
              color: #22272E 100%
            .blue-btn
              background-color: #015DE7
              color: #fff
            .red-btn
              color: #EF4141
              border: 1px solid #FFAAAA;
        .waybill-list
          .look
            display flex
            justify-content: flex-end
            align-items: center
            cursor pointer
            color #015de7
            font-size 12px
            padding 10px 0
            div
              margin-right 5px

    .el-pagination
      margin: 20px auto
      display: flex
      justify-content: center
  .mask
    width 100vw
    height 100vh
    background: rgba(0, 0, 0, .6);
    position: fixed
    top 0
    left: 0
    z-index 2
    display: flex
    align-items: center
    justify-content: center
    .pop
      background: #fff
      width 634px
      border-radius: 4px;
      .title
        display: flex
        justify-content: space-between
        height 77px
        align-items: center
        padding: 0 32px
        img
          width 16px
          height 16px
          transform: translate(16px,-16px)
      .input
        display: flex
        justify-content: center
        align-items: center
        padding: 30px 0 44px
        div
          margin-right: 20px
          color: #1F2329
          font-size: 14px
      .btns
        display: flex
        justify-content: flex-end
        padding-right: 16px 
        width 100%
        padding-bottom: 24px
        div
          width: 80px;
          height: 32px;
          border-radius: 4px;
          text-align: center
          line-height: 30px
          font-size: 14px
          margin-left: 12px
          cursor pointer
        .white-btn
          border: 1px solid rgba(208, 211, 214, 1);
          color: #22272E 100%
        .blue-btn
          background-color: #015DE7
          color: #fff
            
</style>
